import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import www from '../img/world-wide-web (1).png'
import applg from '../img/user-interface.png'
import graphic from '../img/graphic-designer.png'
import vid from '../img/montage.png'
import seo from '../img/seo-img.png'
import webhost from '../img/hosting.png'
import cloud from '../img/cloud-server.png'
import it0soln from '../img/consultant.png'
import cyber from '../img/consulting.png'

const Services = () => {
  return (
    <div className='service-page'>

      <div className="top-service-part">
        <div className="atpa-serv-tp">
          <h1>Service</h1>
          <p>We are here to help you with customized solutions tailored to your specific needs. Whether you're looking for expert advice, a new service, or have any inquiries, reach out to us and we'll get back to you promptly. Let’s discuss how we can help your business grow!
          </p>
        </div>
      </div>

      <div className="partition-sec-service">

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={www} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>Web Development</h1>
          </div>
          <div className="para-serv">
            <p>We build responsive and interactive websites that drive engagement and business growth.</p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/web-app'}><button>Get Started</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>
          </div>
        </div>


        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={applg} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>App Development
            </h1>
          </div>
          <div className="para-serv">
            <p>Create powerful, cross-platform mobile apps designed to enhance your business operations and user experience.
            </p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/web-app'}><button>Explore Apps</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={graphic} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>Graphic Design</h1>
          </div>
          <div className="para-serv">
            <p>Our creative designs help you stand out, communicate your brand, and captivate your audience.</p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/graph-des'}><button>View Portfolio</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={vid} height={55} alt="www" />
          </div>
          <div className="title-sev">
            <h1>Video Editing</h1>
          </div>
          <div className="para-serv">
            <p>Transform your content with professional video editing that tells your story and captivates your viewers.</p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/'}><button>Start Editing</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={seo} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>SEO Optimization</h1>
          </div>
          <div className="para-serv">
            <p>Boost your online visibility and reach more customers with our effective SEO strategies.</p>
          </div>
          <div className="view-serv">
            <Link to={'/contact'}><button>Get Started</button></Link>
            {/* <Link to={'/seo-markting'}><button>Improve Ranking</button></Link> */}

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={webhost} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1> Web Hosting</h1>
          </div>
          <div className="para-serv">
            <p>Reliable and secure web hosting solutions to ensure your website is always online and performing at its best.</p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/'}><button>Choose Hosting</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={cloud} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>Cloud Solutions</h1>
          </div>
          <div className="para-serv">
            <p>Scale your business effortlessly with our Cloud Solutions. We handle migration, security, and maintenance, offering flexible, cost-effective cloud environments.</p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/'}><button>Explore Cloud</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={it0soln} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>IT Consulting</h1>
          </div>
          <div className="para-serv">
            <p>We guide your business through complex tech challenges with strategic IT consulting. From planning to cybersecurity, we provide the expertise to innovate and scale.
            </p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/it-soln'}><button>Learn More</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>

        <div className="partition-boxes-serv">
          <div className="icon">
            <img src={cyber} height={60} alt="www" />
          </div>
          <div className="title-sev">
            <h1>Cybersecurity Services</h1>
          </div>
          <div className="para-serv">
            <p>Protect your business with our comprehensive cybersecurity services. We safeguard your data and networks with proactive defense measures against rising threats.  </p>
          </div>
          <div className="view-serv">
            {/* <Link to={'/'}><button>Secure My Business</button></Link> */}
            <Link to={'/contact'}><button>Get Started</button></Link>

          </div>
        </div>
      </div>



    </div>
  )
}

export default Services