import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header/Header.js';
import MainPage from './WasteFile/MainPage.js';
import Product from './Components/Product.js';
import Pricing from './Components/Pricing.js';
import Contact from './Components/Contact.js';
import Footer from './Header/Footer.js';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import Home from './Components/Home.js';
import Term_Condition from './ExtFtt/Term_Condition.js';
import Career from './ExtFtt/Career.js';
import Privacy_policy from './ExtFtt/Privacy_policy.js';
import Delivery_policy from './ExtFtt/Delivery_policy.js';
import Cancellation_refund_policy from './ExtFtt/Cancellation_refund_policy.js';
import About from './Components/About.js';
import UserData from './UserEXp/UserData.js';
import WebApp from './MorePages/WebApp.js';
import SeoOpt from './MorePages/SeoOpt.js';
import Grapgh from './MorePages/Grapgh.js';
import ItSoln from './MorePages/ItSoln.js';
import Services from './Components/Services.js';

function App() {



  return (
    <>
    <UserData/>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
        <Route path="*" element={<Navigate to={'/'} />} />
          <Route path="/" element={<Home />} />
          <Route path="/homes" element={<MainPage />} />
          <Route path="/product" element={<Product />} />
          <Route path="/resources" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/term-condition" element={<Term_Condition />} />
          <Route path="/privacy-policy" element={<Privacy_policy />} />
          <Route path="/delivery-policy" element={<Delivery_policy />} />
          <Route path="/cancellation-or-refund-policy" element={<Cancellation_refund_policy/>} />
          <Route path="/about-us" element={<About/>} />
          <Route path="/web-app" element={< WebApp/>} />
          <Route path="/seo-markting" element={< SeoOpt/>} />
          <Route path="/graph-des" element={<Grapgh/>} />
          <Route path="/it-soln" element={< ItSoln/>} />
          

        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
