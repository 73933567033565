import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import './contact.css';
import { addDoc, collection } from 'firebase/firestore';
import img from '../img/close.png';

const Contact = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phNumber: '',
        industry: '',
        region: '',
        message: '',
    });
    const [location, setLocation] = useState({ latitude: '', longitude: '' });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        console.log(formData.industry);
    }, [formData.industry]);

    const handleSub = async (e) => {
        e.preventDefault();

        let latitude = '';
        let longitude = '';

        if (navigator.geolocation) {
            const getPosition = () =>
                new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

            try {
                const position = await getPosition();
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
                setLocation({ latitude, longitude });
            } catch (error) {
                console.error('Error retrieving location:', error.message);
            }
        } else {
            console.error('Geolocation is not supported by this browser.');
        }

        try {
            await addDoc(collection(db, 'allUserContactData'), {
                fName: formData.fname,
                lName: formData.lname,
                email: formData.email,
                phoneNumber: formData.phNumber,
                region: formData.region,
                industry: formData.industry,
                location: { latitude, longitude },
                message: formData.message,
            });
            setSuccess('Your message has been sent successfully!');
            setFormData({
                fname: '',
                lname: '',
                email: '',
                phNumber: '',
                region: '',
                industry: '',
                message: '',
            });
            setLocation({ latitude: '', longitude: '' });
            setError(null);
            setTimeout(() => setSuccess(null), 5000); // Clear success message after 5 seconds
        } catch (error) {
            setError("Error adding document: " + error.message);
            setSuccess(null);
        }
    };

    const changeOnTrgt = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className='contact-pg'>
            <div className="img">
                <div className="btnn" onClick={() => { window.history.back() }}>
                    <img src={img} alt="close" />
                </div>
            </div>

            <div className="fomo-form-lgsn">
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <div className="btnnt" onClick={() => { window.history.back() }}>
                    <img src={img} alt="close" />
                </div>

                <div className="ctn-lgsn">
                    <h1>Contact Us</h1>
                </div>

                <form className='forms' onSubmit={handleSub}>
                    <div className="name">
                        <input
                            type="text"
                            name="fname"
                            placeholder="First Name"
                            value={formData.fname}
                            onChange={changeOnTrgt}
                            autoComplete="off"
                            required
                        />
                        <input
                            type="text"
                            name="lname"
                            placeholder="Last Name"
                            value={formData.lname}
                            onChange={changeOnTrgt}
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="det">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={changeOnTrgt}
                            autoComplete="off"
                            required
                        />
                        <input
                            type="tel"
                            name="phNumber"
                            placeholder="Phone number"
                            value={formData.phNumber}
                            onChange={changeOnTrgt}
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="ask-regNdInd">
                        <select name="region" value={formData.region} onChange={changeOnTrgt}>
                            <option value="">--Region</option>
                            <option value="asia-pacific">Asia Pacific</option>
                            <option value="australia&new-zealand">Australia & New Zealand</option>
                            <option value="india">India</option>
                            <option value="north-america">North America</option>
                            <option value="united-kingdom">United Kingdom</option>
                        </select>

                        <select name="industry" value={formData.industry} onChange={changeOnTrgt}>
                            <option value="">--Industry</option>
                            <option value="automotive">Automotive</option>
                            <option value="banking">Banking</option>
                            <option value="automotive">Automotive</option>
                            <option value="banking">Banking</option>
                            <option value="communications-media-info-services">Communications, Media, Info services</option>
                            <option value="consumer-good-services">Consumer Good & Services</option>
                            <option value="education">Education</option>
                            <option value="engineering-construction-operation">Engineering Construction & Operation</option>
                            <option value="professional-services">Professional Services</option>
                            <option value="platforms-software-products">Platforms & Software Products</option>
                            <option value="insurance">Insurance</option>
                            <option value="healthcare">Healthcare</option>
                            <option value="high-tech">High Tech</option>
                            <option value="manufacturing">Manufacturing</option>
                            <option value="public-sector">Public Sector</option>
                            <option value="retail">Retail</option>
                            <option value="travel-logistics">Travel & Logistics</option>
                            <option value="others">Others</option>
                        </select>
                    </div>

                    <div className="txt-ar">
                        <textarea
                            name="message"
                            placeholder="How can we help you?"
                            value={formData.message}
                            onChange={changeOnTrgt}
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="term-req-act">
                        <div className="frt-term-reqact ttt">
                            <input type="checkbox" name="police" id="police" required />
                            <label htmlFor="police">I agree to the use of my information and <a href="/privacy-policy">privacy & policies</a> for communication purposes related to my inquiry.</label>
                        </div>
                        <div className="scnd-term-reqact ttt">
                            <input type="checkbox" name="terms" id="terms" required />
                            <label htmlFor="terms">I accept and agree to all the <a href="/term-condition">terms & conditions</a> set for service seekers before using your services.</label>
                        </div>
                    </div>

                    <div className="btn-sub">
                        <input type="submit" value="Submit" id='btn' />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
