import React from 'react'
import './home.css'

const Product = () => {
  
  return (
    <div className='product-details'>

      <div className="all-cont-product">


        <div className="woe-pro">
          <h1>Product Detail</h1>
          <hr />
        </div>

        <div className="container-card-product">

          <div className="card-product">
            <h1>
              Personal Finance Management Software
            </h1>
            <p>Take control of your finances with Knoxo Tech's Personal Finance Management Software. From budgeting to tracking expenses and managing investments, this tool is designed to make personal finance simple and efficient.
            </p>
          </div>

          <div className="card-product beige">
            <h1>Employee Management Software
            </h1>
            <p>Our Employee Management Software helps businesses organize, monitor, and optimize their workforce. From tracking attendance to managing payroll, this software is a one-stop solution for improving productivity and operational efficiency.
            </p>
          </div>

          <div className="card-product">
            <h1>CA Firm Solutions
            </h1>
            <p>For Chartered Accountancy firms, Knoxo Tech provides a dedicated software solution that simplifies client acquisition and management. With features like tax consultation, ITR filing assistance, and a client portal, this software ensures smooth operations for CA firms.
            </p>
          </div>
          <div className="card-product">
            <h1>Meeting And Email Automation Tool
            </h1>
            <p>Streamline your workflow with Knoxo Tech's Meeting Scheduling and Email Management Software. Schedule meetings, manage calendars, and handle email communications effortlessly—keeping your business connected and on track.
            </p>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Product