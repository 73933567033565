
import './allExpCss.css'

const UserData = () => {






    return null
    //(<>
    //     {cookieCond ?
    //         <div className='cookies'>
    //             <div className="cookie">
    //                 <p>We use essential cookies to make our site work. With your consent, , we may also use  cookies to improve user experience and analyze website traffic. By clicking “Accept,” you agree to our website's cookie use as described in our <u>Cookie Policy</u>.</p>
    //                 <div className="btns">
    //                     <button className='accCookie' onClick={handlCookieClickac}>Accept</button>
    //                     <button className='rejCookie' onClick={handlCookieClickrej}>Reject</button>
    //                 </div>
    //             </div>
    //         </div> : <div></div>}
    // </>);
};

export default UserData;
